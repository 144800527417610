<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <service-links-comp></service-links-comp>
                <div class="board_list text-left" style="box-sizing: border-box;padding: 20px 20px" v-html="tearmContent">

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>
</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import {getServiceMentList} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import ServiceLinksComp from "../../components/links/ServiceLinksComp";
    export default {
        name: "Tearm3",
        components: {
            ServiceLinksComp,
            FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        data(){
            return {
                tearmContent:''
            }
        },
        methods:{
            getServiceMent(){
                getServiceMentList(sportsConst.TYPE_PERSION_INFO).then(res=>{
                    if(res.data.success){
                        try{
                            this.tearmContent = res.data.data.content
                        }catch (e) {

                        }
                    }
                })
            }
        },
        created() {
            this.getServiceMent()
        }
    }
</script>

<style scoped>

</style>